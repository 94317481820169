<template>
  <div class="">

    <el-upload :accept="accept" :file-list="filelist" :on-remove="handleRemove" :action="action" list-type="picture-card" :on-success="handleImageSuccess">
      <i class="el-icon-plus"></i>
    </el-upload>

  </div>
</template>

<script>
export default {
  name: "SingleImageUpload",
  props: {
    value: {
      type: Array,
      default: () => [],
    },
    accept: {
      type: String,
      default: "image/*",
    },
  },
  data() {
    return {
      action: process.env.VUE_APP_BASE_API + "/index/upload",
      tempUrl: [],
      dataObj: { token: "", key: "" },
      filelist: [],
    };
  },
  computed: {
    // imageUrl() {
    //   return this.value;
    // },
  },
  created() {
    if (this.value.length > 0) {
      this.filelist = this.value.map((item, index) => {
        return { name: index, url: item };
      });
      console.log(this.filelist, " this.filelist");
    }

    console.log(this.value, "created");
  },
  methods: {
    emitInput(val) {
      this.$emit("input", this.tempUrl);
    },
    handleImageSuccess(e) {
      let src = "https://performapi.90028.cn" + e.data;
      this.tempUrl.push(src);
      console.log(this.tempUrl, " this.tempUrl");
      this.emitInput();
    },
    handleRemove(file, fileList) {
      let src = "https://performapi.90028.cn";
      this.tempUrl = fileList.map((item) => {
        if (item.response) {
          return src + item.response.data;
        } else {
          return item.url;
        }
      });
      this.emitInput();
    },
  },
};
</script>

<style lang="scss" scoped></style>
