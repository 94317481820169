<template>
  <!-- 选择城市 -->
  <div class="SelectCity">
    <div class="SelectCity_title">热门城市</div>
    <div class="citylist flex_aling_center">
      <div class="citylist_item flex_aling_justify_center" @click="selectCityback(item)" v-for="(item,index) in hotList" :key="index">
        {{item.city_name}}
      </div>

    </div>

    <div class="SelectCity_title">按字母选择</div>
    <div class=" SelectCity_letter">
      <div class="SelectCity_letter_item  flex" v-for="(item,index) in cityList" :key="index">
        <div class="SelectCity_letter_lable">
          {{letterlist[index]}}
        </div>
        <div class="SelectCity_letter_text_box">
          <div class="SelectCity_letter_text" @click="selectCityback(value)" v-for="(value,cityIndex) in item" :key="cityIndex">
            {{value.city_name}}
          </div>
        </div>

      </div>
    </div>

  </div>
</template>

<script>
import { mapState, mapMutations } from "vuex";
export default {
  name: "SelectCity",
  data() {
    return {
      letterlist: [],
    };
  },
  created() {
    this.letterlist = [...Array(26)].map((v, i) => String.fromCharCode(i + 65));
  },
  computed: {
    ...mapState(["cityList", "hotList"]),
  },
  methods: {
    ...mapMutations(["SetCurrentCity"]),
    //  选择城市回调
    selectCityback(item) {
      console.log(item);

      this.SetCurrentCity(item);
      this.$emit("selectCityback", item);
    },
  },
};
</script>

<style lang="scss" scoped>
.SelectCity {
  padding: 15px 25px;
  width: 100%;
  // height: 100%;
  min-height: 500px;
  max-height: 600px;
  overflow: auto;
  background: #ffffff;
  border-radius: 20px;

  .SelectCity_title {
    font-weight: 500;
    font-size: 20px;
    color: #333333;
  }
  .citylist {
    flex-wrap: wrap;
    // margin: 36px 0;
    margin-top: 15px;
    .citylist_item {
      padding: 5px 12px;
      background: #f6f6f6;
      border-radius: 7px;
      font-size: 15px;
      margin-right: 9px;
      margin-bottom: 20px;
    }
  }

  .SelectCity_letter {
    min-height: 800px;
    overflow: auto;
    background-color: fff;
    margin-top: 10px;
    .SelectCity_letter_item {
      // flex-wrap: wrap;
      .SelectCity_letter_lable {
        line-height: 35px;
        font-weight: bold;
        font-size: 20px;
        color: #f43a47;
        margin-right: 23px;
      }
      .SelectCity_letter_text_box {
        display: flex;
        flex-wrap: wrap;
        .SelectCity_letter_text {
          font-size: 15px;
          line-height: 40px;
          text-align: center;
          color: #333;
          margin-right: 20px;
        }
      }
    }
  }
}
</style>

