<template>
  <div class="">
    <template v-if="!isEdit">
      <div class="member flex_aling_center">
        <div class="resumeLine_btn flex_aling_center pointer     edit" v-if="authType==1" @click="isEdit=true">
          <i class="el-icon-edit-outline"></i>
          编辑
        </div>
        <img class="avater" :src="info.member.avatar" alt="">
        <div class="member_info " v-if="info">
          <div class="member_name flex_aling_center">
            <span>{{info.member.real_name}}</span>
            <div class="member_sex flex_aling_center">
              <!-- <img src="@/assets/image/mine/female.png" alt=""> -->
              <i class="el-icon-male" v-if="info.note.sex==1"></i>
              <i class="el-icon-female" v-else></i>
              {{info.note.sex==1?'女':'男'}}
            </div>
          </div>
          <div class="member_right">
            <div class="flex_aling_center member_one ">
              <div class="member_lable">
                视觉年龄：
              </div>
              <div class="member_text">
                {{info.note.begin_age}} - {{info.note.end_age}}岁
              </div>
            </div>
            <div class="flex_aling_center member_one">
              <div class="member_lable">
                现居地：
              </div>
              <div class="member_text">
                {{info.note.address_provice}} {{info.note.address_city}}
              </div>
            </div>
            <div class="flex_aling_center member_one" v-if="info.note.borker_phone">
              <div class="member_lable">
                经纪人电话：
              </div>
              <div class="member_text">
                {{info.note.borker_phone}}
              </div>
            </div>
            <div class="flex_aling_center member_one">
              <div class="member_lable">
                联系电话：
              </div>
              <div class="member_text">
                {{info.member.phone}}
              </div>
            </div>

            <!-- <div class="flex_aling_center member_one">
              <div class="member_lable">
                常用邮箱：
              </div>
              <div class="member_text">
                158749871@qq.com
              </div>
            </div> -->
          </div>

        </div>
      </div>
      <!-- 锚点 -->
      <div class="right_title flex_aling_center" ref="anchor0">
        <div class="right_line flex_aling_center"></div>
        档期
        <div class="setting_dangqi">
          <i class="el-icon-s-help"></i> <span>{{isK?'档期为空闲':'暂无档期'}}</span>
        </div>
      </div>
      <div class="Calendar_setting marinT20  flex">
        <div class="Calendar_setting_item">
          <Calendar :open="false" :markDays="markDays" :isSwitch="false" @onDayClick="onDayClick" :textIsShow="false"></Calendar>
        </div>
        <div class="Calendar_setting_item">
          <Calendar :open="false" :markDays="markDays" :isSwitch="false" :addNum="1" @onDayClick="onDayClick" :textIsShow="false"></Calendar>
        </div>
      </div>

      <div class="right_title flex_aling_center">
        <div class="right_line"></div>
        个人情况
      </div>
      <div class="resumeLine flex_aling_center marinT20">
        <div class="resumelable">籍贯：</div>
        <div class="resumeText"> {{info.note.place_province}} {{info.note.place_city}}</div>
      </div>
      <!-- <div class="resumeLine flex_aling_center">
        <div class="resumelable">出生日期：</div>
        <div class="resumeText">2000.10.14</div>
      </div> -->
      <div class="resumeLine flex_aling_center">
        <div class="resumelable">身高：</div>
        <div class="resumeText">{{info.note.height}}</div>
      </div>
      <div class="resumeLine flex_aling_center">
        <div class="resumelable">体重：</div>
        <div class="resumeText">{{info.note.weight}}</div>
      </div>
      <div class="resumeLine flex_aling_center">
        <div class="resumelable">鞋码：</div>
        <div class="resumeText">{{info.note.shoe_size}}</div>
      </div>
      <div class="resumeLine flex_aling_center">
        <div class="resumelable">三围：</div>
        <div class="resumeText">{{info.note.three_size}}</div>
      </div>
      <div class="resumeLine flex_aling_center">
        <div class="resumelable">兴趣爱好：</div>
        <div class="resumeText">{{info.note.hobby}} </div>
      </div>
      <div class="resumeLine flex_aling_center">
        <div class="resumelable">特长：</div>
        <div class="resumeText">{{info.note.special}} </div>
      </div>

      <div class="resumeLine flex_aling_center">
        <div class="resumelable">个人描述:</div>
        <div class="resumeText">{{info.note.content}} </div>
      </div>
      <!-- <div class="resumeLine flex_aling_center">
        <div class="resumelable">语言：</div>
        <div class="resumeText">普通话、英语 </div>
      </div> -->
    </template>
    <!-- 个人资料的编辑 -->
    <template v-else>
      <editBaseinfo @close="close"></editBaseinfo>
    </template>

    <!-- 上面是个人资料的编辑 -->
    <div class="right_title flex_aling_center flex_js_sb" ref="anchor1">
      <div class="flex_aling_center ">
        <div class="right_line"></div>
        教育经历
      </div>
      <template v-if="!isJy">
        <div v-if="authType==1" class="resumeLine_btn_btn flex_aling_justify_center" @click="isJy=!isJy">
          <i class="el-icon-circle-plus-outline"></i>
          添加
        </div>
      </template>
    </div>
    <!-- 隐藏 -->
    <template v-if="!isJy">
      <div class="education marinT20" v-if="JYlist.length>0">
        <div class="education_tiem" v-for="(value,i) in JYlist" :key="i">
          <div>
            <div class="education_tiem_name">
              <div class="circle">
              </div>
              {{value.school_name}}
            </div>
            <div class="marinT15">
              {{value.major}}
            </div>
            <!-- <div class="marinT15">
              主修科目:软件工程、大型数据库应用与实践、数据 可视化分析、数据仓库与数据挖掘
            </div> -->
          </div>
          <div class="flex_aling_center" v-if="authType==1">
            <div class="resumeLine_btn   pointer flex_aling_center" @click="edit('isJy',value)">
              <i class="el-icon-edit-outline"></i>
              编辑
            </div>
            <div class="resumeLine_btn  pointer flex_aling_center" @click="del('isJy',value)">
              <i class="el-icon-delete"></i>
              删除
            </div>
          </div>
        </div>
      </div>
    </template>
    <div class="add" v-if="isJy">
      <div class="add_title">
        添加教育经历
      </div>
      <div class="add_c">
        <div class="form">
          <el-row :gutter="20">
            <el-form :rules="rules" :model="form" class="demo-form-inline" size="mini" ref="ruleForm" label-width="100px">
              <el-col :span="12" prop="school_name">
                <el-form-item label="学校名称">
                  <el-input v-model="form.school_name" placeholder="请输入内容"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12" prop="major">
                <el-form-item label="所学专业">
                  <el-input v-model="form.major" placeholder="请输入内容"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="12" prop="education">
                <el-form-item label="学历">
                  <el-select style="width:100%" v-model="form.education" placeholder="请选择">
                    <el-option :label="item.name" :value="item.value" v-for="(item,index) in options1" :key="index">
                    </el-option>

                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="12" prop="begin_time">
                <el-form-item label="就读时间">

                  <el-date-picker v-model="form.begin_time" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>
              <el-col :span="12" prop="end_time">
                <el-form-item label="毕业时间">
                  <el-date-picker v-model="form.end_time" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
                  </el-date-picker>
                </el-form-item>
              </el-col>

            </el-form>
          </el-row>
        </div>
      </div>
      <div class="recruit_btns">
        <div class="recruit_lebtn" @click="cencle('isJy')">
          取消
        </div>
        <div class="recruit_btn" @click="save('isJy')">
          确定
        </div>
      </div>
    </div>

    <!-- 教育经历 -->
    <!-- 代表作 -->
    <div class="right_title flex_aling_center flex_js_sb" ref="anchor2">
      <div class="flex_aling_center ">
        <div class="right_line"></div>
        代表作
      </div>
      <template v-if="!isGZ">
        <div v-if="authType==1" class="resumeLine_btn_btn flex_aling_justify_center" @click="isGZ=!isGZ">
          <i class="el-icon-circle-plus-outline"></i>
          添加
        </div>
      </template>

    </div>
    <template v-if="!isGZ">
      <div class="resumeLine marinT20 flex_aling_center flex_js_sb" v-for="(item,index) in GZlist " :key="index">
        <div class="resumeText ">
          {{item.work_name}}
        </div>
        <div class="flex_aling_center" v-if="authType==1">
          <div class="resumeLine_btn pointer flex_aling_center" @click="edit('isGZ',item)">
            <i class="el-icon-edit-outline"></i>
            编辑
          </div>
          <div class="resumeLine_btn pointer flex_aling_center" @click="del('isGZ',item)">
            <i class="el-icon-delete"></i>
            删除
          </div>
        </div>
      </div>
    </template>
    <div class="add" v-if="isGZ">
      <div class="add_title" @click="isGZ=true">
        添加代表作
      </div>
      <div class="add_c">
        <div class="form">
          <el-row :gutter="20">
            <el-form class="demo-form-inline" size="mini" ref="ruleForm" label-width="100px">
              <el-col :span="12" prop="work_name">
                <el-form-item label="代表作名称">
                  <el-input v-model="form.work_name" placeholder="请输入内容"></el-input>
                </el-form-item>
              </el-col>

            </el-form>
          </el-row>
        </div>
      </div>
      <div class="recruit_btns">
        <div class="recruit_lebtn" @click="cencle('isGZ')">
          取消
        </div>
        <div class="recruit_btn" @click="save('isGZ')">
          确定
        </div>
      </div>
    </div>

    <!-- 工作经历 -->
    <!-- 形象/剧照 -->
    <div class="right_title flex_aling_center flex_js_sb" ref="anchor3">
      <div class="flex_aling_center">
        <div class="right_line"></div>
        形象/剧照
      </div>
      <template v-if="!isXX">
        <div v-if="authType==1" class="resumeLine_btn_btn flex_aling_justify_center" @click="isXX=!isXX">
          <i class="el-icon-circle-plus-outline"></i>
          添加
        </div>
      </template>
    </div>
    <template v-if="!isXX">
      <div v-for="(item,index) in XXlist " :key="index">
        <div class="resumeLine marinT20 flex_aling_center flex_js_sb">
          <div class="resumeText ">
            {{item.photo_name}}
          </div>
          <div class="flex_aling_center pointer" v-if="authType==1">
            <div class="resumeLine_btn  flex_aling_center" @click="edit('isXX',item)">
              <i class="el-icon-edit-outline"></i>
              编辑
            </div>
            <div class="resumeLine_btn flex_aling_center" @click="del('isXX',item)">
              <i class="el-icon-delete"></i>
              删除
            </div>
          </div>
        </div>
        <div class="resumeImgs">
          <el-image class="resumeImg" :preview-src-list="item.photo_url" v-for="(value) in item.photo_url " :src="value">
          </el-image>
          <!-- <img class="resumeImg" v-for="(value) in item.photo_url " :src="value" alt=""> -->
        </div>
      </div>
    </template>

    <!-- 操作 -->
    <div class="add" v-if="isXX">
      <div class="add_title">
        添加/编辑其他信息
      </div>
      <div class="add_c">
        <div class="form">
          <el-row :gutter="20">
            <el-form class="demo-form-inline" size="mini" ref="ruleForm" label-width="100px">
              <el-col :span="12" prop="photo_name">
                <el-form-item label="形象/剧照">
                  <el-input v-model="form.photo_name" placeholder="请输入名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="图片">
                  <SingleImage v-model="form.photo_url"></SingleImage>
                </el-form-item>
              </el-col>
            </el-form>
          </el-row>
        </div>
      </div>
      <div class="recruit_btns">
        <div class="recruit_lebtn" @click="cencle('isXX')">
          取消
        </div>
        <div class="recruit_btn" @click="save('isXX')">
          确定
        </div>
      </div>
    </div>
    <!-- 作品视频 -->
    <div class="right_title flex_aling_center flex_js_sb" ref="anchor4">
      <div class="flex_aling_center">
        <div class="right_line"></div>
        作品视频
      </div>
      <template v-if="!isZP">
        <div v-if="authType==1" class="resumeLine_btn_btn flex_aling_justify_center" @click="isZP=!isZP">
          <i class="el-icon-circle-plus-outline"></i>
          添加
        </div>
      </template>

    </div>
    <template v-if="!isZP&&ZPlist.length>0">
      <div v-for="(item,index) in ZPlist " :key="index">
        <div class="resumeLine marinT20 flex_aling_center flex_js_sb">
          <div class="resumeText ">
            {{item.video_name}}
          </div>
          <div class="flex_aling_center pointer" v-if="authType==1">
            <div class="resumeLine_btn flex_aling_center" @click="edit('isZP',item)">
              <i class="el-icon-edit-outline"></i>
              编辑
            </div>
            <div class="resumeLine_btn flex_aling_center" @click="del('isZP',item)">
              <i class="el-icon-delete"></i>
              删除
            </div>
          </div>
        </div>
        <div class="resumeImgs">
          <video class="resumeVideo" controls v-for="(value) in item.video_url " :src="value" alt="" />
        </div>

      </div>
    </template>
    <div class="add" v-if="isZP">
      <div class="add_title">
        添加/编辑其他信息
      </div>
      <div class="add_c">
        <div class="form">
          <el-row :gutter="20">
            <el-form class="demo-form-inline" size="mini" ref="ruleForm" label-width="100px">
              <el-col :span="12" prop="video_name">
                <el-form-item label="视频名称">
                  <el-input v-model="form.video_name" placeholder="请输入名称"></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="24">
                <el-form-item label="视频">
                  <UploadVideo v-model="form.video_url"></UploadVideo>
                </el-form-item>
              </el-col>
            </el-form>
          </el-row>
        </div>
      </div>
      <div class="recruit_btns">
        <div class="recruit_lebtn" @click="cencle('isZP')">
          取消
        </div>
        <div class="recruit_btn" @click="save('isZP')">
          确定
        </div>
      </div>
    </div>

    <!-- 数据截图 -->
    <div class="right_title flex_aling_center flex_js_sb" ref="anchor5">

      <div class="flex_aling_center">
        <div class="right_line"></div>
        数据截图
      </div>
      <template v-if="!isSJ">
        <div v-if="authType==1" class="resumeLine_btn_btn flex_aling_justify_center" @click="isSJ=!isSJ">
          <i class="el-icon-circle-plus-outline"></i>
          添加
        </div>
      </template>
    </div>
    <template v-if="!isSJ&&SJlist.length>0">
      <div v-for="(item,index) in SJlist " :key="index">
        <div class="resumeLine marinT20 flex_aling_center flex_js_sb">
          <div class="resumeText ">
            {{item.data_name}}
          </div>
          <div class="flex_aling_center pointer" v-if="authType==1">
            <div class="resumeLine_btn flex_aling_center" @click="edit('isSJ',item)">
              <i class="el-icon-edit-outline"></i>
              编辑
            </div>
            <div class="resumeLine_btn flex_aling_center" @click="del('isSJ',item)">
              <i class="el-icon-delete"></i>
              删除
            </div>
          </div>
        </div>
        <div class="resumeImgs">
          <el-image class="resumeImg" :preview-src-list="item.data_url" v-for="(value) in item.data_url " :src="value">
          </el-image>

        </div>
      </div>
    </template>
    <div class="add" v-if="isSJ">
      <div class="add_title">
        添加/编辑其他信息
      </div>
      <div class="add_c">
        <div class="form">
          <el-row :gutter="20">
            <el-form class="demo-form-inline" size="mini" ref="ruleForm" label-width="100px">
              <el-col :span="12" prop="real_name">
                <el-form-item label="数据名称">
                  <el-input v-model="form.data_name" placeholder="请输入名称"></el-input>
                </el-form-item>
              </el-col>

              <el-col :span="24">
                <el-form-item label="数据截图">
                  <SingleImage v-model="form.data_url"></SingleImage>
                </el-form-item>
              </el-col>
            </el-form>
          </el-row>
        </div>
      </div>
      <div class="recruit_btns">
        <div class="recruit_lebtn" @click="cencle('isSJ')">
          取消
        </div>
        <div class="recruit_btn" @click="save('isSJ')">
          确定
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import SingleImage from "@/components/SingleImage.vue";
import editBaseinfo from "@/components/memberNotes/editBaseinfo.vue";
import UploadVideo from "@/components/UploadVideo.vue";
import { mapState } from "vuex";
import {
  member_note,
  save_note,
  save_edu,
  del_edu,
  save_video,
  del_video,
  save_photo,
  del_photo,
  save_data,
  del_data,
  save_work,
  del_work,
  save_note_pdf,
  del_note_pdf,
} from "@/utils/network.js";
export default {
  props: ["item"],
  components: {
    editBaseinfo,
    SingleImage,
    UploadVideo,
  },
  data() {
    return {
      isEdit: false,
      isJy: false,
      isGZ: false,
      isXX: false,
      isZP: false,
      isSJ: false,
      JYlist: [],
      GZlist: [],
      XXlist: [],
      ZPlist: [],
      SJlist: [],
      form: {
        real_name: "",
        timer: [],
        photo_url: [],
        video_url: [],
        data_url: [],
      },
      markDays: [],
      options1: [
        {
          name: "小学",
          value: 1,
        },
        {
          name: "初中",
          value: 2,
        },
        {
          name: "中专/中技",
          value: 3,
        },
        {
          name: "高中",
          value: 4,
        },
        {
          name: "大专",
          value: 5,
        },
        {
          name: "本科",
          value: 6,
        },
        {
          name: "硕士",
          value: 7,
        },
        {
          name: "博士",
          value: 8,
        },
      ],
      istype: "",
      imageUrl: "",
      info: {},
      rules: {
        education: [{ required: true, message: "不能为空", trigger: "blur" }],
        work_name: [{ required: true, message: "不能为空", trigger: "blur" }],
        end_time: [{ required: true, message: "不能为空", trigger: "blur" }],
        begin_time: [{ required: true, message: "不能为空", trigger: "blur" }],
        video_name: [{ required: true, message: "不能为空", trigger: "blur" }],
        photo_name: [{ required: true, message: "不能为空", trigger: "blur" }],
      },
      action: process.env.VUE_APP_BASE_API + "/index/upload",
      isK: false,
      currentDaty: "",
    };
  },
  computed: {
    ...mapState(["authType"]),
  },
  created() {
    let timer = new Date(Date.now());
    let m = timer.getTime();
    this.currentDaty = this.$parseTime(m, "{y}-{m}-{d}");

    if (this.item) {
      this.info = this.item;
      this.JYlist = this.info.edu;
      this.GZlist = this.info.work;
      this.XXlist = this.info.photo;
      this.ZPlist = this.info.video;
      this.SJlist = this.info.data;
      if (this.item.usetime.length > 0) {
        this.markDays = this.item.usetime.map((value) => value.day_time);
        this.markDays.includes(this.currentDaty) && (this.isK = true);
      }
      return;
    }
    this.getinfo();
  },
  methods: {
    close(e) {
      this.isEdit = false;
    },
    handleAvatarSuccess(e) {
      let src = "https://performapi.90028.cn" + e.data;
      this.form.photo_url.push(src);
    },
    onDayClick() {},
    // 锚点跳转
    jumpM(index) {
      // console.log(index);

      // console.log(this.$refs.anchor1);

      let anchor = "anchor" + index;
      this.$refs[anchor].scrollIntoView({
        behavior: "smooth",
        block: "start",
      });
    },
    // 获取用户信息回显
    async getinfo() {
      const res = await member_note();
      this.info = res.data;
      if (!res.data.note) {
        this.isEdit = true;
      }
      res.data.edu.length ? (this.JYlist = res.data.edu) : (this.isJy = true);
      res.data.work.length ? (this.GZlist = res.data.work) : (this.isGZ = true);
      res.data.photo.length
        ? (this.XXlist = res.data.photo)
        : (this.isXX = true);
      res.data.video.length
        ? (this.ZPlist = res.data.video)
        : (this.isZP = true);
      res.data.data.length ? (this.SJlist = res.data.data) : (this.isSJ = true);
      // this.info = this.item;
      // this.JYlist = this.info.edu;
      // this.GZlist = this.info.work;
      // this.XXlist = this.info.photo;
      // this.ZPlist = this.info.video;
      // this.SJlist = this.info.data;
      if (res.data.usetime.length > 0) {
        this.markDays = res.data.usetime.map((value) => value.day_time);
        this.markDays.includes(this.currentDaty) && (this.isK = true);
      }
    },

    cencle(value) {
      this.$confirm("确定要退出吗, 数据不会保存?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(() => {
          this[value] = false;
        })
        .catch(() => {});
    },
    async save(value) {
      let res;
      if (value == "isJy") {
        res = await save_edu({ arr: [this.form] });
      } else if (value == "isGZ") {
        res = await save_work({ arr: [this.form] });
      } else if (value == "isXX") {
        res = await save_photo({ arr: [this.form] });
      } else if (value == "isZP") {
        res = await save_video({ arr: [this.form] });
      } else {
        res = await save_data({ arr: [this.form] });
      }

      if (res.code == 200) {
        this.$message({
          message: "操作成功",
          type: "success",
        });
        this.form = {};
        this.getinfo();
        this[value] = false;
      }
    },
    edit(value, item) {
      this.form = item;
      this[value] = true;
    },
    del(value, item) {
      this.$confirm("您确定要删除吗?", "提示", {
        confirmButtonText: "确定",
        cancelButtonText: "取消",
        type: "warning",
      })
        .then(async () => {
          let res;
          if (value == "isJy") {
            console.log(value);
            res = await del_edu({ id: item.id });
          } else if (value == "isGZ") {
            res = await del_work({ id: item.id });
          } else if (value == "isXX") {
            res = await del_photo({ id: item.id });
          } else if (value == "isZP") {
            res = await del_video({ id: item.id });
          } else {
            res = await del_data({ id: item.id });
          }

          if (res.code == 200) {
            this.$message({
              message: "操作成功",
              type: "success",
            });
            this.form = {};
            this.getinfo();
            this[value] = false;
          }
        })
        .catch(() => {});
    },
  },
};
</script>

<style lang="scss" scoped>
.right_title {
  margin-top: 15px;
  font-weight: 600;
  font-size: 15px;
  color: #333333;

  .right_line {
    width: 6px;
    height: 24px;
    background: #f43a47;
    border-radius: 0px 4px 0px 6px;
    margin-right: 14px;
  }
}
// 档期
.setting_dangqi {
  margin-left: 10px;
  font-weight: 500;
  font-size: 15px;
  color: #333333;
  span {
    font-weight: 500;
    font-size: 15px;
    color: #ff9b0c;
  }
  .el-icon-s-help {
    font-size: 16p;
    color: #ff9b0c;
    margin-right: 5px;
  }
}
// 日历设置
.Calendar_setting {
  // background: pink;
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 30px;
  .Calendar_setting_item {
    // height: 400px;
    padding: 10px;
    overflow: hidden;
    border-radius: 10px;
    border: 1px solid #ecebeb;
    width: 48%;
  }
}
// 个人信息
.member {
  position: relative;
  margin-top: 23px;
  display: flex;
  .edit {
    position: absolute;

    top: 0px;
    right: 20px;
  }
  .avater {
    width: 91px;
    height: 91px;
    border-radius: 50%;
    margin-right: 15px;
  }
  .member_sex {
    margin-left: 15px;
    img {
      width: 13px;
      height: 13px;
      margin: 0;
      margin-right: 6px;
    }
    i {
      font-size: 15px;
    }
    .el-icon-male {
      color: red;
    }
    .el-icon-female {
      color: #1789f6;
    }
    font-weight: 400;
    font-size: 12px;
    color: #333333;
  }
  .member_info {
    font-size: 18px;
    color: #666666;
    .member_name {
      font-size: 22px;
      font-weight: 700;
      color: #333333;
    }
    .member_right {
      display: flex;
      flex-wrap: wrap;
      .member_one {
        margin-right: 20px;
        margin-top: 5px;
        // width: 33%;
        .member_lable {
          // width: 120px;
          font-size: 14px;
          color: #999999;
        }
        .member_text {
          font-size: 14px;
          color: #333333;
        }
      }
    }
  }
}
// 教育经历
.education {
  border-left: 1px solid #dfdcdc;
  padding-left: 40px;

  .education_tiem {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    font-size: 14px;
    color: #666666;
    padding: 10px 0;
  }

  .education_tiem_name {
    .circle {
      position: absolute;
      left: -45px;
      top: 0;
      width: 9px;
      height: 9px;
      background: #f21f09;
      border-radius: 50%;
      z-index: 1;
    }
    font-weight: 600;
    font-size: 16px;
    color: #333333;
  }
}
.resumeLine {
  height: 30px;
}
.resumeImgs {
  display: flex;
  flex-wrap: wrap;
  .resumeImg,
  .resumeVideo {
    margin-top: 10px;
    width: 100px;
    height: 100px;
    margin-right: 10px;
    border-radius: 20px;
  }
  .resumeVideo {
    border-radius: 0;
    width: 200px;
    height: 200px;
  }
}

.resumeLine_btn {
  margin-right: 10px;
  i {
    font-size: 18px;
  }
  font-size: 13px;
  color: #999999;
}
.resumeLine_btn_btn {
  width: 82px;
  height: 29px;
  background: #fff0ee;
  border-radius: 8px;
  border: 1px solid #f43a47;
  font-weight: 400;
  font-size: 14px;
  color: #f43a47;
}
// 字体统一
.resumelable {
  font-size: 14px;
  color: #666666;
}
.resumeText {
  font-size: 14px;
  color: #333333;
}

// 添加
.add {
  padding: 20px;
  .add_title {
    font-size: 16px;
    color: #333333;
    font-weight: 600;
    margin-bottom: 15px;
  }
  .add_c {
    padding: 20px;
    width: 100%;

    background: #f8f8f8;
    border-radius: 15px;
  }
}

// 输入框样式
::v-deep .el-input__inner {
  height: 44px;
  background: #ffffff;
  border-radius: 8px;
  border: none !important;
}

::v-deep .el-upload {
  background: #ffffff;
}
::v-deep .el-textarea__inner {
  background: #ffffff;
  border-radius: 8px;
  border: none !important;
}

// 图片相册
.avatar-uploader .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar {
  width: 178px;
  height: 178px;
  display: block;
}
.recruit_btns {
  margin-top: 10px;

  display: flex;
  justify-content: flex-end;
  .recruit_btn,
  .recruit_lebtn {
    margin-left: 15px;
    width: 134px;
    height: 53px;
  }
}
.el-form-item {
  display: flex;
  align-items: center;
}
::v-deep .el-form-item__content {
  width: 100%;
  margin-left: 0 !important;
}
</style>

