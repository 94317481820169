<template>
  <!-- 新的岗位招聘 只用在首页 -->
  <div class="ecruitment  flex" :class="{'classBorder':isBorder}">
    <div class="ecruitment_new_left">
      <div class="ecruitment_info flex">
        <img class="ecruitment_img" :src="item.crew_photo" alt="">
        <div class="ecruitment_info_right">
          <div class="ecruitment_name flex_js_sb flex_aling_center">
            <span> {{item.crew_name}}</span>
            <div>{{item.type}}</div>
          </div>
          <div class="ecruitment_tag flex_aling_center">
            <div class="ecruitment_tag_item flex_aling_justify_center">
              开机时间:{{item.begin_time}}
            </div>
            <div class="ecruitment_tag_item flex_aling_justify_center">
              拍摄地点: {{item.province}}{{item.city}}
            </div>
          </div>
          <div class="ecruitment_text  flex_aling_center">
            <div class="ecruitment_text_label">
              风格类型：
            </div>
            {{item.style_type}}
          </div>
          <div class="ecruitment_text  flex_aling_center">
            <div class="ecruitment_text_label">
              拍摄导演：
            </div>
            {{item.director}}
          </div>
        </div>
      </div>
      <div class="ecruitment_c  marinT10   tow_ellipsis">
        {{item.story || '暂无故事概况'}}

      </div>
      <div class="line">

      </div>
      <div class="ecruitment_company  marinT10  flex_aling_center">
        <img :src="item.company_logo" alt="">
        {{item.company_name}}
        <div class="ecruitment_company_name flex_aling_center">
          <img src="@/assets/image/image/gongsi.png" alt="">
          {{item.nature_name}}
          {{item.scale_name}}
        </div>
      </div>
    </div>
    <div class="ecruitment_new_right" v-if="item.two_job.length>0">
      <div class="ecruitment_new_right_item" @click="Details(value,item)" v-for="(value,valueIndex) in item.two_job " :key="valueIndex">
        <div class="shixi flex_aling_justify_center ">
          需要试戏
        </div>
        <div class="ecruitment_new_right_item_title flex_aling_center">
          {{value.movie_name}} <span v-if="value.movie_price_mian==2">
            面议
          </span>
        </div>
        <div class="ecruitment_new_right_item_line">
          <span class="ecruitment_new_right_item_label">角色属性：</span>{{value.movie_personattr}}
        </div>
        <div class="ecruitment_new_right_item_line">
          <span class="ecruitment_new_right_item_label">台词量级：</span>
          {{value.movie_personattr}}
        </div>
      </div>
      <div class="ecruitment_new_right_item ecruitment_new_right_item-zhanwei" v-if="item.two_job.length==1">

      </div>
    </div>
  </div>
</template>

<script >
export default {
  props: {
    isBorder: {
      type: Boolean,
      require: false,
      default: false,
    },
    item: {
      type: Object,
      require: false,
      default: () => {},
    },
  },
  data() {
    return {};
  },
  methods: {
    // 职位详情
    Details(value, item) {
      console.log(value);
      console.log(item);

      value.company_member_id = item.company_member_id;
      // console.log(value,'value');

      this.$router.push({
        path: "/positionDetails",
        query: { item: JSON.stringify(value) },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.ecruitment {
  padding: 29px 20px;
  // padding-bottom: 5px;
  width: 100%;
  height: 270px;
  // background: pink;
  background: #fff;
  border-radius: 18px;
  justify-content: space-between;
  .ecruitment_new_left {
    width: 98%;
  }
  .ecruitment_new_right {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 100%;
    .shixi {
      position: absolute;
      right: 0;
      top: 0;
      width: 69px;
      height: 27px;
      background: #f43a47;
      border-radius: 0px 10px 0px 10px;

      font-size: 10px;
      color: #ffffff;
    }
    .ecruitment_new_right_item {
      position: relative;
      display: flex;
      flex-direction: column;

      justify-content: space-around;
      padding: 10px;
      width: 311px;
      height: 48%;
      background: linear-gradient(0deg, #ffffff, #fff3f4);
      border-radius: 10px;
      border: 1px solid #eaeef4;
      .ecruitment_new_right_item_title {
        font-size: 15px;
        color: #333333;
        font-weight: 700;
        span {
          margin-left: 15px;
          font-size: 12px;
          color: #f43a47;
        }
      }
      .ecruitment_new_right_item_line {
        font-size: 13px;
        color: #333;
        .ecruitment_new_right_item_label {
          color: #666666;
        }
      }
    }
    .ecruitment_new_right_item-zhanwei {
      background: rgba(255, 255, 255, 0);
      border: 0;
    }
  }
  .ecruitment_info {
    .ecruitment_img {
      width: 93px;
      height: 129px;
      font-size: 22px;
      color: #333333;
      border-radius: 10px;
      margin-right: 10px;
    }
    .ecruitment_info_right {
      width: 320px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .ecruitment_name {
        width: 100%;
        height: 21px;
        font-size: 22px;
        color: #333333;
        div {
          font-size: 18px;
          color: #f43a47;
        }
      }
      .ecruitment_tag {
        .ecruitment_tag_item {
          margin-right: 10px;
          padding: 2px 10px;

          background: #f5f5f5;
          border-radius: 4px;

          font-size: 13px;
          color: #666666;
        }
      }
      .ecruitment_text {
        font-size: 14px;
        color: #000;
        .ecruitment_text_label {
          color: #666666;
        }
      }
    }
  }
  .ecruitment_c {
    min-height: 40px;
    font-weight: 500;
    font-size: 13px;
    color: #666666;
    line-height: 26px;
  }
  .line {
    width: 100%;
    height: 1px;
    background: #f4f6f9;
    margin: 10px 0;
  }
  .ecruitment_company {
    img {
      width: 29px;
      height: 28px;
      border-radius: 50%;
      margin-right: 10px;
    }

    font-weight: 500;
    font-size: 13px;
    color: #333333;
  }

  .ecruitment_company_name {
    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
      margin: 0 10px;
    }

    font-size: 13px;
    color: #999999;
  }
}

.classBorder {
  border: 1px solid #eae8e8;
}
</style>

