<template>
  <div class="FilterMenu">
    <div class="FilterMenu_item flex_aling_center">
      <div class="FilterMenu_item_label">
        角色属性:
      </div>
      <div class="FilterMenu_item_select " :class="{'FilterMenu_active':oneIndex==0}" @click="toggle(0,'不限')">不限</div>
      <div class="FilterMenu_item_select " :class="{'FilterMenu_active':oneIndex==1}" @click="toggle(1,'主演')">主演</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':oneIndex==2}" @click="toggle(2,'主要配角')">主要配角</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':oneIndex==3}" @click="toggle(3,'特约')">特约</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':oneIndex==4}" @click="toggle(4,'小特')">小特</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':oneIndex==5}" @click="toggle(5,'前景')">前景</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':oneIndex==6}" @click="toggle(6,'特型')">特型</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':oneIndex==7}" @click="toggle(7,'群演')">群演</div>
    </div>
    <div class="FilterMenu_item flex_aling_center">
      <div class="FilterMenu_item_label">
        拍摄时间:
      </div>
      <div class="FilterMenu_item_select " :class="{'FilterMenu_active':oneIndex==0}" @click="toggleTow(0,0,0)">不限</div>
      <div class="FilterMenu_item_select " :class="{'FilterMenu_active':towIndex==1}" @click="toggleTow(1,'急招')">急招</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':towIndex==2}" @click="toggleTow(2,1,5)">1-5天</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':towIndex==3}" @click="toggleTow(3,5,10)">5-10天</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':towIndex==4}" @click="toggleTow(4,10,15)">10-15天</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':towIndex==5}" @click="toggleTow(5,15,20)">15-20天</div>
    </div>
    <div class="FilterMenu_item flex_aling_center">
      <div class="FilterMenu_item_label">
        性别:
      </div>
      <div class="FilterMenu_item_select " :class="{'FilterMenu_active':threeIndex==0}" @click="toggleThree(0,0)">不限</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':threeIndex==1}" @click="toggleThree(1,'男')">男</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':threeIndex==2}" @click="toggleThree(2,'女')">女</div>
    </div>
    <div class="FilterMenu_item flex_aling_center">
      <div class="FilterMenu_item_label">
        视觉年龄:
      </div>
      <div class="FilterMenu_item_select " :class="{'FilterMenu_active':fourIndex==0}" @click="toggleFour(0,0,0)">不限</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':fourIndex==1}" @click="toggleFour(1,10,15)">10-15岁</div>
      <div class="FilterMenu_item_select" :class="{'FilterMenu_active':fourIndex==2}" @click="toggleFour(2,20,25)">20-25岁</div>

    </div>

  </div>
</template>

<script>
export default {
  data() {
    return {
      oneIndex: 0,
      towIndex: 0,
      threeIndex: 0,
      fourIndex: 0,
      selectObj: {},
    };
  },
  methods: {
    toggle(index, value) {
      this.oneIndex = index;
      this.selectObj.movie_personattr = value;
      this.select();
    },
    toggleTow(index, value, value1) {
      this.towIndex = index;
      if (value == "急招") {
        this.selectObj.is_fast = 2;
      } else {
        this.selectObj.movie_time_begin = value;
        this.selectObj.movie_time_end = value1;
      }
      this.select();
    },
    toggleThree(index, value) {
      this.threeIndex = index;
      this.selectObj.need_sex = value;
      this.select();
    },
    toggleFour(index, value, value1) {
      this.fourIndex = index;

      this.selectObj.need_age_begin = value;
      this.selectObj.need_age_end = value1;
      this.select();
    },
    select() {
      this.$emit("select", this.selectObj);
    },
  },
};
</script>

<style lang="scss" scoped>
.FilterMenu {
  padding: 32px 29px;
  width: 100%;
  height: 233px;
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  border-radius: 20px;
  .FilterMenu_item {
    .FilterMenu_item_label {
      width: 100px;
      margin-right: 20px;
      font-weight: 600;
      font-size: 20px;
      color: #333333;
    }
    .FilterMenu_item_select {
      margin-right: 15px;
      font-size: 17px;
      color: #333333;
      padding: 5px 10px;
    }
    .FilterMenu_active {
      display: flex;
      align-items: center;
      justify-content: center;
      // width: 64px;
      // height: 30px;
      padding: 5px 10px;
      background: #f43a47;
      border-radius: 6px;
      color: #fff;
    }
  }
}
</style>

