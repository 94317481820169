<template>
  <div class="container">
    <div class="timer-head" v-if="monthOpen == false">
      <div class="nowtime">
        {{nowtime}}
      </div>
    </div>
    <div class="calendar-wrapper">
      <div class="header" v-if="headerBar">
        <template v-if="isSwitch">
          <div class="preWidth" @click="changeMonth('pre')">
            <div class="pre">
              <u-icon name="rewind-left-fill" size="20" color="#ef4924"></u-icon>
              <i class="el-icon-d-arrow-left"></i>

            </div>
          </div>
        </template>
        <div>{{y+'年'+formatNum(m)+'月'}}</div>

        <template v-if="isSwitch">
          <div class="nextWidth" @click="changeMonth('next')">
            <div class="next">
              <i class="el-icon-d-arrow-right"></i>

            </div>
          </div>
        </template>
      </div>
      <div class="week">
        <div class="week-day" v-for="(item, index) in weekDay" :key="index">{{ item }}</div>
      </div>
      <!-- 日历 -->
      <div :class="{ hide: !monthOpen }" class="content_rili" :style="{ height: height }">
        <div :style="{ top: positionTop + 'px' }" class="days">
          <!-- 具体的每一个 -->
          <div class="item" v-for="(item, index) in dates" :key="index">
            <!-- today: isToday(item.year, item.month, item.date), -->
            <div class="day" @click="selectOne(item, $event)" :class="{
                              choose: choose == `${item.year}-${item.month}-${item.date}`&&item.isCurM,
                              nolm: !item.isCurM,
                              isWorkDay: isWorkDay(item.year, item.month, item.date)
                          }">
              {{ Number(item.date) }}
            </div>
            <div class="markDay" v-if="isMarkDay(item.year, item.month, item.date)&&item.isCurM"></div>
            <!-- <div class="today-text" v-if="isToday(item.year, item.month, item.date)">今</div> -->
          </div>
        </div>
      </div>
      <template v-if="textIsShow">
        <div class="weektoggle" @click="toggle">
          <div class="drop" v-if="monthOpen == false">
            <image src="/static/image/index_pages/green-drop.png" mode="" v-if="collapsible"></image>
            <div class="drop-text">展开</div>
          </div>
          <div class="drop" v-if="monthOpen == true">
            <image src="/static/image/index_pages/green-drop.png" style="transform: rotate(180deg);" mode="" v-if="collapsible"></image>
            <div class="drop-text">收起</div>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: "ren-calendar",
  props: {
    ismonthOpen: {
      type: Boolean,
      default: false,
    },
    // 星期几为第一天(0为星期日)
    weekstart: {
      type: Number,
      default: 1,
    },
    // 标记的日期
    markDays: {
      type: Array,
      default: () => {
        return [];
      },
    },
    // 是否展开
    open: {
      type: Boolean,
      default: true,
    },
    //是否可收缩
    collapsible: {
      type: Boolean,
      default: true,
    },
    //未来日期是否不可点击
    disabledAfter: {
      type: Boolean,
      default: false,
    },
    // 自定义日期选择
    textIsShow: {
      type: Boolean,
      default: true,
    },
    // 切换状态
    isSwitch: {
      type: Boolean,
      default: true,
      require: false,
    },

    // 设置自定义年月
    addNum: {
      type: Number,
      default: 0,
      require: false,
    },
  },
  data() {
    return {
      weektext: ["日", "一", "二", "三", "四", "五", "六"],
      y: new Date().getFullYear(), // 年
      m: new Date().getMonth() + 1, // 月
      dates: [], // 当前月的日期数据
      positionTop: 0,
      monthOpen: false,
      choose: "",
      nowtime: "",
      headerBar: false, // 月份切换按钮
    };
  },
  created() {
    this.dates = this.monthDay(this.y, this.m);

    if (this.addNum) {
      this.changeMonth("next");
    }
  },
  mounted() {
    this.choose = this.getToday().date;
    this.init();
    this.getnowtime();
    setTimeout(() => {}, 1000);
    this.$nextTick(() => {
      !this.open && this.toggle();
    });
  },
  computed: {
    // 顶部星期栏
    weekDay() {
      return this.weektext
        .slice(this.weekstart)
        .concat(this.weektext.slice(0, this.weekstart));
    },
    height() {
      return (this.dates.length / 7) * 50 + "px";
    },
  },
  methods: {
    formatNum(num) {
      let res = Number(num);
      return res < 10 ? "0" + res : res;
    },
    getToday() {
      let date = new Date();
      let y = date.getFullYear();
      let m = date.getMonth();
      let d = date.getDate();
      let week = new Date().getDay();
      let weekText = ["日", "一", "二", "三", "四", "五", "六"];
      let formatWeek = "星期" + weekText[week];
      let today = {
        date: y + "-" + this.formatNum(m + 1) + "-" + this.formatNum(d),
        week: formatWeek,
      };
      return today;
    },
    getnowtime() {
      let date = new Date();
      let y = date.getFullYear();
      let m = date.getMonth();
      let d = date.getDate();
      this.nowtime = y + "-" + this.formatNum(m + 1) + "-" + this.formatNum(d);
    },
    // 获取当前月份数据
    monthDay(y, month) {
      let dates = [];
      let m = Number(month);
      let firstDayOfMonth = new Date(y, m - 1, 1).getDay(); // 当月第一天星期几
      let lastDateOfMonth = new Date(y, m, 0).getDate(); // 当月最后一天
      let lastDayOfLastMonth = new Date(y, m - 2, 0).getDate(); // 上一月的最后一天
      let weekstart = this.weekstart == 7 ? 0 : this.weekstart;
      let startDay = (() => {
        // 周初有几天是上个月的
        if (firstDayOfMonth == weekstart) {
          return 0;
        } else if (firstDayOfMonth > weekstart) {
          return firstDayOfMonth - weekstart;
        } else {
          return 7 - weekstart + firstDayOfMonth;
        }
      })();
      let endDay = 7 - ((startDay + lastDateOfMonth) % 7); // 结束还有几天是下个月的
      for (let i = 1; i <= startDay; i++) {
        dates.push({
          date: this.formatNum(lastDayOfLastMonth - startDay + i),
          day: weekstart + i - 1 || 7,
          month: m - 1 >= 0 ? this.formatNum(m - 1) : 12,
          year: m - 1 >= 0 ? y : y - 1,
        });
      }
      for (let j = 1; j <= lastDateOfMonth; j++) {
        dates.push({
          date: this.formatNum(j),
          day: (j % 7) + firstDayOfMonth - 1 || 7,
          month: this.formatNum(m),
          year: y,
          isCurM: true, //是否当前月份
        });
      }
      for (let k = 1; k <= endDay; k++) {
        dates.push({
          date: this.formatNum(k),
          day: (lastDateOfMonth + startDay + weekstart + k - 1) % 7 || 7,
          month: m + 1 <= 11 ? this.formatNum(m + 1) : 0,
          year: m + 1 <= 11 ? y : y + 1,
        });
      }
      // console.log(dates);
      return dates;
    },
    isWorkDay(y, m, d) {
      //是否工作日
      let ymd = `${y}/${m}/${d}`;
      let formatDY = new Date(ymd.replace(/-/g, "/"));
      let week = formatDY.getDay();
      if (week == 0 || week == 6) {
        return false;
      } else {
        return true;
      }
    },
    isFutureDay(y, m, d) {
      //是否未来日期
      let ymd = `${y}/${m}/${d}`;
      let formatDY = new Date(ymd.replace(/-/g, "/"));
      let showTime = formatDY.getTime();
      let curTime = new Date().getTime();
      if (showTime > curTime) {
        return true;
      } else {
        return false;
      }
    },
    // 标记日期
    isMarkDay(y, m, d) {
      let flag = false;
      for (let i = 0; i < this.markDays.length; i++) {
        let dy = `${y}-${m}-${d}`;
        if (this.markDays[i] == dy) {
          flag = true;
          break;
        }
      }
      return flag;
    },
    isToday(y, m, d) {
      let checkD = y + "-" + m + "-" + d;
      let today = this.getToday().date;
      if (checkD == today) {
        return true;
      } else {
        return false;
      }
    },
    // 展开收起
    toggle() {
      this.monthOpen = !this.monthOpen;
      this.headerBar = !this.headerBar;
      // monthOpen  当前的展开状态， false 关闭 true 展开
      if (this.monthOpen) {
        this.positionTop = 0;
        console.log(this.positionTop);
      } else {
        let index = -1;
        this.dates.forEach((i, x) => {
          this.isToday(i.year, i.month, i.date) && (index = x);
        });
        this.positionTop = -((Math.ceil((index + 1) / 7) || 1) - 1) * 30;
      }
    },
    init() {
      let index = -1;
      this.dates.forEach((i, x) => {
        this.isToday(i.year, i.month, i.date) && (index = x);
      });
      this.positionTop = -((Math.ceil((index + 1) / 7) || 1) - 1) * 30;
    },
    // 点击回调
    selectOne(i, event) {
      let date = `${i.year}-${i.month}-${i.date}`;
      let selectD = new Date(date).getTime();
      let curTime = new Date().getTime();
      let week = new Date(date).getDay();
      let weekText = ["日", "一", "二", "三", "四", "五", "六"];
      let formatWeek = "星期" + weekText[week];
      let response = {
        date: date,
        week: formatWeek,
      };
      if (!i.isCurM) {
        // console.log('不在当前月范围内');
        return false;
      }
      if (selectD > curTime) {
        if (this.disabledAfter) {
          console.log("未来日期不可选");
          return false;
        } else {
          this.choose = date;
          this.$emit("onDayClick", response);
        }
      } else {
        this.choose = date;
        this.$emit("onDayClick", response);
      }
      this.nowtime = response.date;
      console.log("---", response);
    },
    //改变年月
    changYearMonth(y, m) {
      this.dates = this.monthDay(y, m);
      this.y = y;
      this.m = m;
    },
    // 上一个  下一个
    changeMonth(type) {
      if (type == "pre") {
        if (this.m + 1 == 2) {
          this.m = 12;
          this.y = this.y - 1;
        } else {
          this.m = this.m - 1;
        }
      } else {
        if (this.m + 1 == 13) {
          this.m = 1;
          this.y = this.y + 1;
        } else {
          this.m = this.m + 1;
        }
      }
      this.dates = this.monthDay(this.y, this.m);
    },
  },
};
</script>

<style scoped>
.calendar-wrapper {
  color: #000;
  width: 100%;
  background-color: #fff;
  font-size: 12px;
  text-align: center;
  margin-bottom: 10px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 40px;
  font-size: 16px;
  color: #f21f09;
  font-weight: bold;
  border-bottom: 4px solid #f21f09;
}

.pre,
.next {
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pre image,
.next image {
  width: 100%;
  height: 100%;
}

.week {
  display: flex;
  align-items: center;
  height: 40px;
  line-height: 40px;
}

.week div {
  flex: 1;
}

.content_rili {
  position: relative;
  overflow: hidden;
  transition: height 0.4s ease;
}

.days {
  transition: top 0.3s;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
}

.item {
  position: relative;
  display: block;
  height: 40px;
  line-height: 40px;
  width: calc(100% / 7);

  display: flex;
  align-items: center;
  justify-content: center;
}

.day {
  font-style: normal;
  display: inline-block;
  vertical-align: middle;
  width: 42px;
  height: 40px;
  line-height: 40px;
  font-size: 10px;
  overflow: hidden;
  border-radius: 16px;
}

/* 点击选择的日期 */
.choose {
  width: 40px;
  height: 40px;
  background: #ffe5e1;
  color: #f21f09;
  border-radius: 50%;
  display: flex;
  align-content: center;
  justify-content: center;
}

.nolm {
  opacity: 0.5;
}

/* 工作日颜色 */
.isWorkDay {
}

.notSigned {
  font-style: normal;
  width: 8px;
  height: 8px;
  background: #fa7268;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  bottom: 0;
  pointer-events: none;
}

.today {
  color: #fff;
  /* background: linear-gradient(180deg, #08CC4E 0%, #F21F09 100%); */
}

.workDay {
  font-style: normal;
  width: 8px;
  height: 8px;
  background: #4d7df9;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  bottom: 0;
  pointer-events: none;
}

.markDay {
  font-style: normal;
  width: 5px;
  height: 5px;
  background: #fc7a64;
  border-radius: 10px;
  position: absolute;
  left: 50%;
  bottom: -0px;
  transform: translateX(-50%);
  pointer-events: none;
}

.hide {
  height: 80px !important;
}

.dropDown {
  width: 50px;
  height: 50px;
  background-color: #fc7a64;
}

.weektoggle {
  height: 20px;
  position: relative;
  bottom: -10px;
  margin: 0 auto;
  background-color: #ffffff;
  padding: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.drop {
  display: flex;
  align-items: center;
}

.weektoggle image {
  width: 28px;
  height: 28px;
  margin-right: 12px;
}

.drop-text {
  font-weight: 400;
  color: #f21f09;
  font-size: 12px;
}

.timer-head {
  position: relative;
}

.timer-head-img {
  height: 132px;
  width: 100%;
}

.timer-head-img image {
  width: 100%;
  height: 100%;
}

.nowtime {
  font-weight: 500;
  font-size: 12px;
  bottom: 28px;
  font-weight: 700;
  font-size: 12px;
  color: #333333;
}
</style>