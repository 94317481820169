<template>
  <!-- 岗位招聘 -->
  <div class="ecruitment " :class="{'classBorder':isBorder}">
    <div class="ecruitment_info flex">
      <img class="ecruitment_img" :src="item.cover_photo" alt="">
      <div class="ecruitment_info_right">
        <div class="ecruitment_name flex_js_sb flex_aling_center">
          <span> {{item.movie_name}}</span>
          <div v-if="item.movie_price_mian==2">面议</div>
        </div>
        <div class="ecruitment_tag flex_aling_center">
          <div class="ecruitment_tag_item flex_aling_justify_center">
            拍摄周期:{{item.movie_time}}
          </div>
          <div class="ecruitment_tag_item flex_aling_justify_center">
            拍摄地点: {{item.movie_address}}
          </div>
        </div>
        <div class="ecruitment_text  flex_aling_center">
          <div class="ecruitment_text_label">
            风格类型：
          </div>
          {{item.movie_attribute}}
        </div>
        <div class="ecruitment_text  flex_aling_center">
          <div class="ecruitment_text_label">
            角色属性：
          </div>
          {{item.movie_personattr}}
        </div>
      </div>
    </div>
    <div class="ecruitment_c  marinT10   tow_ellipsis">
      {{item.movie_content || '暂无简介'}}
    </div>
    <div class="line">
    </div>
    <div class="ecruitment_company  marinT10  flex_aling_center ellipsis">
      <img :src="item.company_logo" alt="">
      {{item.company_name}}
      <div class="ecruitment_company_name flex_aling_center">
        <img src="@/assets/image/image/gongsi.png" alt="">
        <div class="ellipsis">{{item.nature_name}} <span>|</span> {{item.scale_name}}<span>|</span>{{item.industry_name}}</div>
      </div>
    </div>
  </div>
</template>

<script >
export default {
  props: {
    isBorder: {
      type: Boolean,
      require: false,
      default: false,
    },
    item: {
      type: Object,
      require: false,
      default: false,
    },
  },
  created() {
    console.log(this.item, "itemitemitemitem");
  },
};
</script>

<style lang="scss" scoped>
.ecruitment {
  padding: 29px 20px;
  padding-bottom: 5px;
  width: 100;
  height: 280px;
  // background: pink;
  background: #fff;
  border-radius: 18px;
  .ecruitment_info {
    .ecruitment_img {
      width: 93px;
      height: 129px;
      font-size: 22px;
      color: #333333;
      border-radius: 10px;
      margin-right: 10px;
    }
    .ecruitment_info_right {
      width: 320px;
      display: flex;
      flex-direction: column;
      justify-content: space-around;
      .ecruitment_name {
        width: 100%;
        height: 21px;
        font-size: 22px;
        color: #333333;
        div {
          font-size: 18px;
          color: #f43a47;
        }
      }
      .ecruitment_tag {
        .ecruitment_tag_item {
          margin-right: 10px;
          padding: 2px 10px;

          background: #f5f5f5;
          border-radius: 4px;

          font-size: 13px;
          color: #666666;
        }
      }
      .ecruitment_text {
        font-size: 12px;
        color: #000;
        .ecruitment_text_label {
          color: #666666;
        }
      }
    }
  }
  .ecruitment_c {
    min-height: 50px;
    font-weight: 500;
    font-size: 14px;
    color: #666666;
    line-height: 26px;
  }
  .line {
    width: 100%;
    height: 1px;
    background: #f4f6f9;
    margin: 10px 0;
  }
  .ecruitment_company {
    img {
      width: 29px;
      height: 28px;
      border-radius: 50%;
      margin: 0 5px;
    }
    font-weight: 500;
    font-size: 13px;
    color: #333333;
    .ellipsis {
      width: 210px;
    }
  }

  .ecruitment_company_name {
    img {
      width: 20px;
      height: 20px;
      border-radius: 50%;
    }

    font-size: 13px;
    color: #999999;
  }
}

.classBorder {
  border: 1px solid #eae8e8;
}
</style>

